import React, { useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import { HiMenuAlt4, HiX } from "react-icons/hi";
import { motion } from "framer-motion";
import { navbarItems } from '../../constants/common';

// import { images } from "../../constants";
import "./styles.scss";

const Navbar = () => {
  const { pathname } = useLocation()
  const [toggle, setToggle] = useState(false);
  const modifiedPathname = pathname.length > 1 ? pathname.substring(1) : pathname;

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        {/* <img src={images.logo} alt="logo" /> */}
        <span className="app_navbar_logo_image">Jithin Sebastian</span>
      </div>
      <ul className="app__navbar-links">
        {navbarItems.map((item) => (
          <li
            className={`app__flex p-text ${item === modifiedPathname ? 'active': ''}`}
            key={`link-${item}`}
          >
            <div />
            {/* <a href={`#${item}`}>{item}</a> */}
            <Link
              // to={`${item}`}
              to={'/'}
            >
              {item === '/' ? 'Home' : item}
            </Link>
          </li>
        ))}
      </ul>

      <div className="app__navbar-menu">
        <HiMenuAlt4 onClick={() => setToggle(true)} />

        {toggle && (
          <motion.div
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: "easeOut" }}
          >
            <HiX onClick={() => setToggle(false)} />
            <ul>
              {navbarItems.map((item) => (
                <li
                  key={item}
                  className={item === modifiedPathname ? 'active': ''}
                >
                  {/* <a href={`#${item}`} onClick={() => setToggle(false)}>
                    {item}
                  </a> */}
                  <Link
                    to={'/'}
                    onClick={() => setToggle(false)}
                  >{item === '/' ? 'Home' : item}</Link>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
