import { useRouteError, Link } from "react-router-dom";
import { AppWrap, MotionWrap } from "../../wrapper";

const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <div className="error__page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
      <p>
        <Link to='/'>Goto Home</Link>
      </p>
    </div>
  );
}

export default AppWrap(
    MotionWrap(ErrorPage, "error__page"),
    "errorPage",
    "app__primarybg"
  );
