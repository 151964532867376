export const skills = [
    {
        "_id": "fafddb89-ef5b-4ebf-ac09-d74ce374b205",
        "_updatedAt": "2023-01-29T14:48:38Z",
        "order": 0,
        "bgColor": "#edf2f8",
        "_createdAt": "2022-06-11T13:54:47Z",
        "_rev": "FNv4wUuFm2Uspt9bguygaD",
        "_type": "skills",
        "icon": {
            "_type": "image",
            "asset": {
                "_type": "reference",
                "_ref": "image-66bbf9242b1ccffebb5d46f376f5036b527fad48-480x480-png"
            }
        },
        "name": "JavaScript"
    },
    {
        "bgColor": "#edf2f8",
        "_rev": "8YFq3tJawrFlG3qN5BcAC3",
        "_updatedAt": "2023-01-29T14:48:44Z",
        "_createdAt": "2022-06-11T14:50:16Z",
        "_type": "skills",
        "icon": {
            "asset": {
                "_ref": "image-e0af31fa74b8093940bc2416e2cf22ea39f9575d-512x512-png",
                "_type": "reference"
            },
            "_type": "image"
        },
        "name": "React",
        "_id": "6ebb3b9e-6134-4bb3-b6eb-1e90c029a5c5",
        "order": 1
    },
    {
        "_id": "eacc0f3f-1cd6-4564-b395-c83f6b527aee",
        "_createdAt": "2022-06-12T08:08:40Z",
        "_rev": "fUW1ltzeG9pXW58PkrscBs",
        "_type": "skills",
        "icon": {
            "_type": "image",
            "asset": {
                "_type": "reference",
                "_ref": "image-5b6e5467f6d7136061937b59d6ec3639f775e61b-820x820-png"
            }
        },
        "name": "Material UI",
        "_updatedAt": "2023-01-29T14:48:44Z",
        "order": 2,
        "bgColor": "#edf2f8"
    },
    {
        "bgColor": "#edf2f8",
        "icon": {
            "_type": "image",
            "asset": {
                "_ref": "image-330de7ee5cc9eec45b2ecfb1676c2af7773d93ab-1200x1200-png",
                "_type": "reference"
            }
        },
        "name": "Tailwind CSS",
        "_id": "a2a4e1d1-1d1b-4c96-8859-d519802ffeac",
        "_updatedAt": "2023-01-29T14:48:44Z",
        "order": 3,
        "_createdAt": "2023-01-29T14:47:55Z",
        "_rev": "FNv4wUuFm2Uspt9bguyiSv",
        "_type": "skills"
    },
    {
        "_updatedAt": "2023-01-29T14:48:43Z",
        "bgColor": "#edf2f8",
        "_createdAt": "2022-06-10T03:49:22Z",
        "icon": {
            "_type": "image",
            "asset": {
                "_ref": "image-d8c8025b1695a3f14f849b99afc71d917ef40813-480x480-png",
                "_type": "reference"
            }
        },
        "_id": "6f9edd26-6787-48bc-948d-19640f076ad2",
        "order": 4,
        "_rev": "FNv4wUuFm2Uspt9bguyiKH",
        "_type": "skills",
        "name": "Node.js"
    },
    {
        "bgColor": "#edf2f8",
        "_rev": "8YFq3tJawrFlG3qN5BcA3U",
        "_id": "4655b37c-c8e1-44b1-a758-63369168c5ad",
        "name": "mongoDB",
        "_updatedAt": "2023-01-29T14:48:43Z",
        "order": 5,
        "_createdAt": "2022-06-11T14:00:22Z",
        "_type": "skills",
        "icon": {
            "crop": {
                "top": 0.20574814814814824,
                "left": 0.059259259259259275,
                "bottom": 0,
                "_type": "sanity.imageCrop",
                "right": 0.059259259259259234
            },
            "hotspot": {
                "_type": "sanity.imageHotspot",
                "width": 0.8814814814814815,
                "x": 0.5,
                "y": 0.6028740740740741,
                "height": 0.7942518518518518
            },
            "_type": "image",
            "asset": {
                "_ref": "image-462830272e4ef10133b95b95c8031ae0a2dee949-1526x1538-png",
                "_type": "reference"
            }
        }
    },
    {
        "bgColor": "#edf2f8",
        "icon": {
            "asset": {
                "_ref": "image-aad302b4fdf435bbe8cca61c2cb80f5e930e6dfe-352x340-png",
                "_type": "reference"
            },
            "_type": "image"
        },
        "name": "Framer Motion",
        "order": 6,
        "_createdAt": "2023-01-29T13:03:46Z",
        "_rev": "fUW1ltzeG9pXW58Pkrsc6C",
        "_type": "skills",
        "_id": "35f81902-953d-4f5d-84ae-ee92fa9e59e3",
        "_updatedAt": "2023-01-29T14:48:42Z"
    },
    {
        "_createdAt": "2022-06-11T14:44:21Z",
        "icon": {
            "_type": "image",
            "asset": {
                "_ref": "image-8553c8394e898be259ce747ae6cc84eb66fc220b-1316x1126-webp",
                "_type": "reference"
            }
        },
        "name": "Docker",
        "_id": "08ace882-d6e5-41db-b570-b79bffd5510d",
        "order": 7,
        "bgColor": "#edf2f8",
        "_rev": "fUW1ltzeG9pXW58Pkrsc0W",
        "_type": "skills",
        "_updatedAt": "2023-01-29T14:48:39Z"
    },
    {
        "_id": "39cdb9e5-c6f3-418d-a327-e99dd9067aaf",
        "order": 8,
        "bgColor": "#edf2f8",
        "_createdAt": "2022-06-11T14:27:09Z",
        "_rev": "FNv4wUuFm2Uspt9bguyhyf",
        "_type": "skills",
        "icon": {
            "_type": "image",
            "asset": {
                "_ref": "image-a804a741fb26f6c236c73086a87cfc9f64106401-480x480-png",
                "_type": "reference"
            }
        },
        "name": "git",
        "_updatedAt": "2023-01-29T14:48:42Z"
    },
    {
        "_type": "skills",
        "icon": {
            "asset": {
                "_ref": "image-2c3fee978c1b30fa999b70d2ee9d89fc1a1ca551-512x512-png",
                "_type": "reference"
            },
            "_type": "image"
        },
        "name": "Python",
        "_id": "5abcc518-b8f9-4285-9609-a634b59952b3",
        "order": 9,
        "bgColor": "#edf2f8",
        "_createdAt": "2022-06-11T14:47:27Z",
        "_rev": "fUW1ltzeG9pXW58Pkrsbsi",
        "_updatedAt": "2023-01-29T14:48:36Z"
    },
    {
        "_createdAt": "2022-06-11T14:36:01Z",
        "icon": {
            "_type": "image",
            "asset": {
                "_ref": "image-ae6dfa5d33650d0664e26ce9ccb6cb318f2546b7-4000x3097-png",
                "_type": "reference"
            }
        },
        "name": "Azure",
        "order": 10,
        "bgColor": "#edf2f8",
        "_type": "skills",
        "_id": "7a3e0852-3615-44ec-b2b2-47a9be8554d5",
        "_updatedAt": "2023-01-29T14:48:38Z",
        "_rev": "FNv4wUuFm2Uspt9bguygRZ"
    },
    {
        "name": "Keycloak",
        "_id": "ae97a8ad-1e06-42fd-badb-5df0266e029b",
        "_updatedAt": "2023-01-29T14:48:42Z",
        "bgColor": "#edf2f8",
        "_type": "skills",
        "icon": {
            "_type": "image",
            "asset": {
                "_ref": "image-0db1b4462d300db93edecaffb2145b32eb911ee9-256x256-png",
                "_type": "reference"
            }
        },
        "_createdAt": "2022-06-11T14:14:56Z",
        "_rev": "8YFq3tJawrFlG3qN5BcA0d",
        "order": 11
    },
    {
        "bgColor": "#edf2f8",
        "_type": "skills",
        "name": "TensorFlow",
        "_id": "1763d8eb-15fa-48c8-bb00-0b65731b7bac",
        "_updatedAt": "2023-01-29T14:48:42Z",
        "_createdAt": "2022-06-11T14:53:33Z",
        "_rev": "FNv4wUuFm2Uspt9bguyhlh",
        "icon": {
            "asset": {
                "_ref": "image-5bf6e5a395899743e210d41d9d305cd4b8bde2d7-512x512-png",
                "_type": "reference"
            },
            "_type": "image"
        },
        "order": 12
    }
];

export const experiences = [
    {
        "order": 0,
        "works": [
            {
                "company": "Nanyang Technological University",
                "_key": "69dd49a5f2fa",
                "desc": "Electrical & Electronics Engineering (Highest Distinction). Recipient of DSTA Gold Medal and NTU President Research Scholar.",
                "_type": "workExperience",
                "name": "Undergraduate Student"
            }
        ],
        "year": "2015",
        "_createdAt": "2022-06-11T15:01:28Z",
        "_rev": "OrQ6z7w0WB8N4bm29FJyCm",
        "_type": "experiences",
        "_id": "acf91419-f525-4eb5-af0a-c8da2b4e750b",
        "_updatedAt": "2022-06-13T07:19:53Z"
    },
    {
        "works": [
            {
                "desc": "Implemented DMZ, WIPS, IDS, 2FA, SIEM, conducted RA and VAPT. ",
                "_type": "workExperience",
                "name": "Project Engineer",
                "company": "Land Transport Authority",
                "_key": "0286fc934d9a"
            }
        ],
        "year": "2018",
        "_createdAt": "2022-06-10T03:52:08Z",
        "_rev": "S6NcJ8LE6UwKyiSmUADPrm",
        "_type": "experiences",
        "_id": "cc2e1f36-dff8-454d-b19d-7ee08624c8c3",
        "_updatedAt": "2022-06-13T07:19:23Z",
        "order": 1
    },
    {
        "year": "2020",
        "_createdAt": "2022-06-11T15:04:02Z",
        "_rev": "S6NcJ8LE6UwKyiSmUADOiw",
        "_type": "experiences",
        "_id": "45cab322-1cf8-423e-9c45-7696a165c0ed",
        "_updatedAt": "2022-06-13T07:18:38Z",
        "order": 2,
        "works": [
            {
                "_type": "workExperience",
                "name": "Postgraduate Student / Researcher",
                "company": "National University of Singapore",
                "_key": "a48d76bd5b5e",
                "desc": "Master of Computing (CS). Conducted R&D on various cybersecurity implementations using Machine Learning techniques."
            }
        ]
    },
    {
        "_type": "experiences",
        "_id": "fe0f835b-212d-4f6f-bb49-aaa7cb4f8811",
        "_updatedAt": "2022-06-11T15:38:01Z",
        "order": 3,
        "works": [
            {
                "_type": "workExperience",
                "name": "Software Engineer",
                "company": "Defence Science & Technology Agency",
                "_key": "3da47ad0e5d9",
                "desc": "Full Stack Engineer (React, MongoDB, FeathersJS, MQTT, Kafka, Keycloak, Gitlab)"
            }
        ],
        "year": "2021",
        "_createdAt": "2022-06-11T15:06:30Z",
        "_rev": "OrQ6z7w0WB8N4bm29FJgnk"
    }
]; 

export const about = [
    {
        "_createdAt": "2022-06-10T03:25:05Z",
        "description": "I design and implement user interface components for JavaScript web applications using the React library ecosystem. ",
        "order": 0,
        "_updatedAt": "2022-06-11T15:43:26Z",
        "imgUrl": {
            "hotspot": {
                "height": 0.6234841143036748,
                "_type": "sanity.imageHotspot",
                "width": 0.5907534246575341,
                "x": 0.5008561643835615,
                "y": 0.48526676969014354
            },
            "_type": "image",
            "asset": {
                "_ref": "image-adbe3177516c041c572875fa128409e9101aeaee-1238x648-png",
                "_type": "reference"
            },
            "crop": {
                "_type": "sanity.imageCrop",
                "right": 0.1986301369863015,
                "top": 0,
                "left": 0.20547945205479448,
                "bottom": 0
            }
        },
        "_rev": "fEO11EA0NM7P8KkaVVFnC6",
        "_type": "abouts",
        "_id": "6ea1d5dd-a5b9-4352-81c1-fb0f8849ce5f",
        "title": "React Developer"
    },
    {
        "_id": "f0ad8b62-5dbe-4607-8e11-277e701fcf16",
        "title": "Backend Developer",
        "order": 1,
        "_type": "abouts",
        "_createdAt": "2022-06-10T14:12:06Z",
        "_rev": "OrQ6z7w0WB8N4bm29ABdGS",
        "description": "I design and configure servers, database and IAM system to ensure web application functionality and security.",
        "_updatedAt": "2022-06-11T15:43:27Z",
        "imgUrl": {
            "hotspot": {
                "_type": "sanity.imageHotspot",
                "width": 0.05821917808219235,
                "x": 0.638698630136986,
                "y": 0.5,
                "height": 0.3114122751425924
            },
            "_type": "image",
            "asset": {
                "_type": "reference",
                "_ref": "image-efb8e853d1d192726e70c7d2121b01300dfdd1b6-1370x770-jpg"
            },
            "crop": {
                "left": 0.24143835616438353,
                "bottom": 0,
                "_type": "sanity.imageCrop",
                "right": 0,
                "top": 0
            }
        }
    },
    {
        "_type": "abouts",
        "title": "Web3 Learner",
        "order": 2,
        "_id": "3f82ad80-c208-40aa-954c-defa1f38de6c",
        "_updatedAt": "2022-06-11T15:43:27Z",
        "imgUrl": {
            "_type": "image",
            "asset": {
                "_ref": "image-491380283ac6208c37b304ab8eb56e8c6be02b96-1254x836-jpg",
                "_type": "reference"
            }
        },
        "_createdAt": "2022-06-10T14:22:04Z",
        "_rev": "OrQ6z7w0WB8N4bm29ABd6m",
        "description": "Attracted to the idea behind Web3, I am developing my skill to create contracts for Ethereum-based applications using Solidity."
    }
];

export const certificates = [
    {
        "organisation": "BlackHat",
        "order": 0,
        "imgurl": {
            "hotspot": {
                "_type": "sanity.imageHotspot",
                "width": 0.8799019607843137,
                "x": 0.5061274509803921,
                "y": 0.5024509803921569,
                "height": 0.8529411764705882
            },
            "_type": "image",
            "asset": {
                "_type": "reference",
                "_ref": "image-115d892856d21cbc9b9a68b55192f9ceaf4d38d9-1000x1000-jpg"
            },
            "crop": {
                "_type": "sanity.imageCrop",
                "right": 0.05392156862745101,
                "top": 0.07598039215686277,
                "left": 0.06617647058823528,
                "bottom": 0.07107843137254899
            }
        },
        "_rev": "8ifCTTQVuIgHhVudVnzGOt",
        "_type": "certificates",
        "name": "Attacking and Securing APIs",
        "_createdAt": "2022-06-11T16:22:49Z",
        "_id": "d016b44e-e96b-4c79-ad00-d920ba41129d",
        "detail": "This is a fully hands-on practical concentrated course on securing and attacking web and cloud APIs. APIs are everywhere nowadays: In web apps, embedded systems, enterprise apps, cloud environments and even IoT, and it is becoming increasingly necessary to learn how to defend, secure and attack API implementation and infrastructure. This training aims to engage you in creating secure modern APIs, while showing you both modern and contemporary attack vectors.",
        "_updatedAt": "2022-06-11T16:27:07Z"
    },
    {
        "_rev": "4jn2Zslf7ISPK7J6Ey2vmq",
        "_type": "certificates",
        "organisation": "Microsoft",
        "_updatedAt": "2022-06-11T16:27:13Z",
        "order": 1,
        "imgurl": {
            "_type": "image",
            "asset": {
                "_type": "reference",
                "_ref": "image-eaa1d3e164203a35665c7356e350b97d4728b3fe-600x600-png"
            }
        },
        "name": "Microsoft Certified: Azure Fundamentals",
        "_id": "74862aaf-bae4-4de7-a137-bfb2b003a2cd",
        "detail": "Earners of the Azure Fundamentals certification have demonstrated foundational level knowledge of cloud services and how those services are provided with Microsoft Azure.",
        "_createdAt": "2022-06-11T16:18:10Z"
    },
    {
        "_updatedAt": "2022-06-11T16:27:17Z",
        "imgurl": {
            "_type": "image",
            "asset": {
                "_ref": "image-41f4ac2b02ebced40682d93690c764ca2b39efe7-600x600-png",
                "_type": "reference"
            }
        },
        "_type": "certificates",
        "_id": "f7cac212-1ffe-45b5-87ee-db491d453745",
        "organisation": "Microsoft",
        "detail": "Earners of the Azure AI Fundamentals certification have demonstrated foundational knowledge of machine learning (ML) and artificial intelligence (AI) concepts and related Microsoft Azure services.",
        "order": 2,
        "_createdAt": "2022-06-11T16:19:54Z",
        "_rev": "8ifCTTQVuIgHhVudVnzGLZ",
        "name": "Microsoft Certified: Azure AI Fundamentals"
    },
    {
        "imgurl": {
            "_type": "image",
            "asset": {
                "_type": "reference",
                "_ref": "image-d69fb2a593ec48cfe14f5971b1d0067bb4beff59-600x600-png"
            }
        },
        "_type": "certificates",
        "name": "Microsoft Certified: Azure Data Fundamentals",
        "detail": "Earners of the Azure Data Fundamentals certification have demonstrated foundational knowledge of core data concepts and how they are implemented using Microsoft Azure data services.",
        "_createdAt": "2022-06-11T16:20:49Z",
        "_rev": "KJrKQbpoT84EuIzI8g7HL7",
        "organisation": "Microsoft",
        "_id": "78d7c0e8-170f-4299-835e-390207b45ec9",
        "_updatedAt": "2022-06-11T16:27:17Z",
        "order": 3
    },
    {
        "_updatedAt": "2022-06-11T16:27:17Z",
        "order": 4,
        "imgurl": {
            "_type": "image",
            "asset": {
                "_ref": "image-bacd7a2a628ee7c396aba4e7fde45a81a55bde38-600x600-png",
                "_type": "reference"
            }
        },
        "_createdAt": "2022-06-11T16:20:22Z",
        "_rev": "4jn2Zslf7ISPK7J6Ey2ve0",
        "_type": "certificates",
        "name": "Microsoft Certified: Security, Compliance, and Identity Fundamentals",
        "_id": "e32f34a8-8a76-4b80-8e1f-800aa6ba8070",
        "organisation": "Microsoft",
        "detail": "Earners of the Security, Compliance, and Identity Fundamentals demonstrate a functional understanding of security, compliance, and identity (SCI) across cloud-based and related Microsoft services."
    },
    {
        "organisation": "CompTIA",
        "_updatedAt": "2022-06-11T16:27:19Z",
        "order": 5,
        "detail": "Earners of the CompTIA Security+ certification have the knowledge and skills necessary to perform core security functions required of any cybersecurity role. CompTIA Security+ professionals know how to identify and address potential threats, attacks and vulnerabilities and they have established techniques in risk management, risk mitigation, threat management and intrusion detection.",
        "imgurl": {
            "_type": "image",
            "asset": {
                "_ref": "image-ff02fb60f456789533d8a65eb29a3a4004eba5e8-600x600-png",
                "_type": "reference"
            }
        },
        "_createdAt": "2022-06-11T16:16:57Z",
        "_rev": "KJrKQbpoT84EuIzI8g7HGL",
        "_type": "certificates",
        "name": "CompTIA Security+ ce Certification",
        "_id": "c7fd9b39-0149-43ec-a56c-c44d9869ca6c"
    },
    {
        "_createdAt": "2022-06-11T16:16:14Z",
        "name": "CompTIA A+ ce Certification",
        "organisation": "CompTIA",
        "detail": "Earners of the CompTIA A+ certification are proven problem solvers who are able to perform critical IT support tasks including device configuration, data backup and recovery, and operating system configuration. CompTIA A+ certified professionals have demonstrated baseline security skills for IT support roles and are able to detect and remove malware, address privacy concerns, and troubleshoot core service challenges.",
        "_updatedAt": "2022-06-11T16:27:18Z",
        "imgurl": {
            "asset": {
                "_ref": "image-dd16b9f30b87758c65002f7030492307f4b2ca4d-600x600-png",
                "_type": "reference"
            },
            "_type": "image"
        },
        "_type": "certificates",
        "_id": "82938819-56ff-4670-94ed-35b71b256d1f",
        "order": 6,
        "_rev": "8ifCTTQVuIgHhVudVnzGm9"
    },
    {
        "_type": "certificates",
        "name": "CompTIA IT Fundamentals (ITF+) Certification",
        "_id": "bbc77c49-b1f8-4e5b-bba2-501fb21f0846",
        "_updatedAt": "2022-06-11T16:27:19Z",
        "order": 7,
        "imgurl": {
            "_type": "image",
            "asset": {
                "_ref": "image-de55756735f55359a25f60db8e18f316feafe1a1-600x600-png",
                "_type": "reference"
            }
        },
        "_createdAt": "2022-06-11T16:14:23Z",
        "_rev": "8ifCTTQVuIgHhVudVnzGfV",
        "organisation": "CompTIA",
        "detail": "Earners of the CompTIA ITF+ certification have the knowledge and skills required to identify and explain the basics of computing, IT infrastructure, application and software, software development, database fundamentals, and security. These IT candidates have demonstrated the ability to install software, establish basic network connectivity, and identify and prevent basic security risks."
    },
    {
        "imgurl": {
            "asset": {
                "_type": "reference",
                "_ref": "image-dd16b9f30b87758c65002f7030492307f4b2ca4d-600x600-png"
            },
            "_type": "image"
        },
        "_type": "certificates",
        "name": "CompTIA A+ ce Certification",
        "_createdAt": "2022-06-11T16:16:14Z",
        "_rev": "pl3qlk-nry-faz-pyw-mst4lc2nw",
        "organisation": "CompTIA",
        "_id": "drafts.82938819-56ff-4670-94ed-35b71b256d1f",
        "detail": "Earners of the CompTIA A+ certification are proven problem solvers who are able to perform critical IT support tasks including device configuration, data backup and recovery, and operating system configuration. CompTIA A+ certified professionals have demonstrated baseline security skills for IT support roles and are able to detect and remove malware, address privacy concerns, and troubleshoot core service challenges.",
        "_updatedAt": "2022-06-11T16:26:34Z"
    }
];

export const works = [
    {
        "_type": "works",
        "description": "It has the functionality to sort movie based on genres, viewing movie and actor details, adding favorites or watchlist.",
        "title": "Netflix Clone App",
        "_updatedAt": "2023-02-20T13:58:04Z",
        "order": 0,
        "imgUrl": {
            "_type": "image",
            "asset": {
                "_ref": "image-f8d54f76b9e5861fe45adfad7ec67f606a6909b9-1635x1719-png",
                "_type": "reference"
            }
        },
        "projectLink": "https://faked-netflix.netlify.app/",
        "_rev": "Y6OlKt1Tb3zYa0B6rwTq3h",
        "tags": [
            "React JS"
        ],
        "_createdAt": "2023-02-01T10:18:38Z",
        "codeLink": "https://github.com/zhenyu92/netflix-clone",
        "_id": "ee0cd2ec-189f-4ace-bb3c-5be64d930614"
    },
    {
        "codeLink": "https://github.com/zhenyu92/renting-app",
        "_type": "works",
        "description": "It has the functionality to perform CRUD, authentication, pagination, sorting and filtering, as well as a dashboard.",
        "_id": "94bcc2f9-98b8-4dda-95ca-df1929ca4968",
        "_updatedAt": "2023-02-20T13:58:04Z",
        "order": 1,
        "tags": [
            "React JS",
            ""
        ],
        "imgUrl": {
            "_type": "image",
            "asset": {
                "_ref": "image-d5f1563f6a93d6ab9cf202982ff69fa901ce75cb-1813x1536-png",
                "_type": "reference"
            }
        },
        "_createdAt": "2023-02-20T13:54:38Z",
        "_rev": "Y6OlKt1Tb3zYa0B6rwTpzW",
        "title": "Property Renting App",
        "projectLink": "https://renting-app.netlify.app/"
    },
    {
        "title": "TikTok Clone App",
        "order": 2,
        "tags": [
            "React JS"
        ],
        "imgUrl": {
            "_type": "image",
            "asset": {
                "_ref": "image-aa4fb7bfc1e1f8fe1341476619e723b5420b0d87-1508x1717-png",
                "_type": "reference"
            }
        },
        "projectLink": "https://tiktik-cloning.netlify.app/",
        "codeLink": "https://github.com/zhenyu92/tiktok-clone",
        "description": "It has the functionality to login / logout, post video clip with caption,  as well as like and comment other posts.",
        "_id": "e4b78199-7171-48f9-b0b3-f7ede7051ddf",
        "_createdAt": "2023-01-29T08:33:34Z",
        "_rev": "3l9dKkxQyLpFijsnTttKlS",
        "_type": "works",
        "_updatedAt": "2023-02-20T13:58:02Z"
    },
    {
        "description": "It has the functionality to generate everything from memes and art to beautiful UI/UX designs with texts.",
        "order": 3,
        "tags": [
            "React JS"
        ],
        "imgUrl": {
            "asset": {
                "_ref": "image-3a83caf8dbd4a27015a44fe99a233be078a9336f-1857x1672-png",
                "_type": "reference"
            },
            "_type": "image"
        },
        "_createdAt": "2023-01-29T08:40:47Z",
        "_rev": "3l9dKkxQyLpFijsnTttKcE",
        "_id": "15886c8c-d273-42ba-9060-0cec184d5f42",
        "title": "AI Image Generation App",
        "_updatedAt": "2023-02-20T13:58:02Z",
        "projectLink": "https://derrick-dalle.netlify.app/",
        "codeLink": "https://github.com/zhenyu92/dall-e-clone",
        "_type": "works"
    },
    {
        "projectLink": "https://jsm-headphone.netlify.app/",
        "_id": "026e4560-6f05-4886-8a03-30c5d6d7c5e0",
        "title": "Ecommerce App",
        "imgUrl": {
            "asset": {
                "_type": "reference",
                "_ref": "image-0073d988e88f699c2a01d1796808752f36f45975-1388x1222-png"
            },
            "_type": "image"
        },
        "codeLink": "https://github.com/zhenyu92/ecommerce-app",
        "_rev": "4koiwqlUYRSwSoNI0ToXt8",
        "_type": "works",
        "description": "It includes the ability to add and edit products using Sanity, shopping cart, and checkout functionalities using Stripe.",
        "_updatedAt": "2023-02-22T00:59:27Z",
        "order": 4,
        "tags": [
            "React JS"
        ],
        "_createdAt": "2022-06-11T10:16:07Z"
    },
    {
        "projectLink": "https://fitness-react-app.netlify.app/",
        "_createdAt": "2022-07-30T04:49:12Z",
        "codeLink": "https://github.com/zhenyu92/fitness-app",
        "_type": "works",
        "title": "Fitness App",
        "tags": [
            "React JS"
        ],
        "imgUrl": {
            "asset": {
                "_ref": "image-0ecc6a6de947f9e0e5bb0c655da774ddc6a4b673-1825x1142-png",
                "_type": "reference"
            },
            "crop": {
                "bottom": 0,
                "_type": "sanity.imageCrop",
                "right": 0.5393835616438355,
                "top": 0,
                "left": 0
            },
            "hotspot": {
                "height": 1,
                "_type": "sanity.imageHotspot",
                "width": 0.4606164383561645,
                "x": 0.23030821917808225,
                "y": 0.5
            },
            "_type": "image"
        },
        "_rev": "4koiwqlUYRSwSoNI0TocAp",
        "description": "It has the functionality to choose exercise categories and specific muscle groups, and pull related videos from YouTube.",
        "_id": "6c5582b4-b800-498a-baae-94fab37f6ee4",
        "_updatedAt": "2023-02-22T01:00:00Z",
        "order": 5
    },
    {
        "imgUrl": {
            "hotspot": {
                "x": 0.3767123287671233,
                "y": 0.5098127723044313,
                "height": 0.014719158456647171,
                "_type": "sanity.imageHotspot",
                "width": 0.339041095890411
            },
            "_type": "image",
            "asset": {
                "_ref": "image-84f34cfd9621fb04d319e4df24a00168ac4b944a-1904x1330-png",
                "_type": "reference"
            },
            "crop": {
                "top": 0,
                "left": 0,
                "bottom": 0,
                "_type": "sanity.imageCrop",
                "right": 0.1284246575342466
            }
        },
        "_type": "works",
        "description": "It includes the ability to return quotation according to user's specifications via email service. Based on Material-UI.",
        "title": "Commercial App",
        "order": 6,
        "_updatedAt": "2023-02-22T00:58:11Z",
        "tags": [
            "React JS"
        ],
        "projectLink": "https://arc-development-site.netlify.app/",
        "_createdAt": "2022-07-30T04:54:50Z",
        "codeLink": "https://github.com/zhenyu92/commercial-quote",
        "_rev": "4koiwqlUYRSwSoNI0ToPEX",
        "_id": "9fe8eaef-719d-410e-b59c-72964cc886f0"
    },
    {
        "imgUrl": {
            "asset": {
                "_ref": "image-c14584b3ec822cc7eaefa797bd318b551097ddca-1340x847-png",
                "_type": "reference"
            },
            "crop": {
                "_type": "sanity.imageCrop",
                "right": 0.1095890410958904,
                "top": 0,
                "left": 0,
                "bottom": 0
            },
            "hotspot": {
                "height": 1,
                "_type": "sanity.imageHotspot",
                "width": 0.8904109589041096,
                "x": 0.4452054794520548,
                "y": 0.5
            },
            "_type": "image"
        },
        "_createdAt": "2022-06-11T10:25:11Z",
        "codeLink": "https://github.com/zhenyu92/web3-app",
        "_rev": "NrmrKaEva7EC4fBj8Fe8Dv",
        "_id": "67379774-55cb-468b-af47-acc1042701de",
        "order": 7,
        "projectLink": "https://react-app-web3.herokuapp.com/",
        "_type": "works",
        "description": "It includes the ability to connect to your crypto wallet via Metamask, as well as perform transactions.",
        "title": "Blockchain App",
        "_updatedAt": "2023-02-22T00:58:57Z",
        "tags": [
            "Web 3.0"
        ]
    },
    {
        "imgUrl": {
            "hotspot": {
                "y": 0.43500248756218907,
                "height": 0.8700049751243781,
                "_type": "sanity.imageHotspot",
                "width": 1,
                "x": 0.5
            },
            "_type": "image",
            "asset": {
                "_ref": "image-9df9f7a8bd1d2439e9ebb09c3d8219315ad020bf-1588x1610-jpg",
                "_type": "reference"
            },
            "crop": {
                "bottom": 0.12999502487562187,
                "_type": "sanity.imageCrop",
                "right": 0,
                "top": 0,
                "left": 0
            }
        },
        "_createdAt": "2022-06-11T10:19:10Z",
        "_id": "7ba2ef8e-73fb-4de6-8f9e-ac04120618fd",
        "title": "Admin Dashboard",
        "order": 8,
        "tags": [
            "React JS"
        ],
        "projectLink": "https://amazon-admin-dashboard.netlify.app/",
        "codeLink": "https://github.com/zhenyu92/admin-dashboard",
        "_rev": "4koiwqlUYRSwSoNI0Tofmq",
        "_type": "works",
        "description": "It includes one Dashboard, Three Pages, Four Apps, and Seven fully functional charts. User is able to define themes.",
        "_updatedAt": "2023-02-22T01:00:31Z"
    },
    {
        "_rev": "sDfK9U7ElIsqXIKq9F2xEf",
        "_type": "works",
        "description": "A deep learning model that will predict the probability of an employee leaving a company using Keras sequential layer.",
        "title": "ANN Model using Keras",
        "tags": [
            "Others"
        ],
        "imgUrl": {
            "crop": {
                "_type": "sanity.imageCrop",
                "right": 0.29794520547945225,
                "top": 0,
                "left": 0.06164383561643835,
                "bottom": 0.03656218008092427
            },
            "hotspot": {
                "height": 0.9634378199190757,
                "_type": "sanity.imageHotspot",
                "width": 0.6404109589041092,
                "x": 0.38184931506849296,
                "y": 0.48171890995953787
            },
            "_type": "image",
            "asset": {
                "_ref": "image-2abe23d15687413c7ff0eec22452b0ff445df574-1920x1080-jpg",
                "_type": "reference"
            }
        },
        "_createdAt": "2022-06-11T10:33:02Z",
        "_id": "c3315eb7-3d35-49d7-acc9-9e237a142202",
        "_updatedAt": "2023-02-20T13:58:02Z",
        "order": 9,
        "projectLink": "https://github.com/zhenyu92/DL_ANN_Employee_Retention",
        "codeLink": "https://github.com/zhenyu92/DL_ANN_Employee_Retention"
    }
];