import { Outlet } from "react-router-dom";
import Navbar from "./components/Navbar";
import { Home } from "./pages";

const Layout = () => {
    return (
      <>
          <>
          <Navbar/>
          {/* <Home/> */}
          </>
        <main>
          {/* 2️⃣ Render the app routes via the Layout Outlet */}
          <Outlet />
        </main>
        <footer>©️ me 2023</footer>
      </>
    );
};

export default Layout;