import {
  createBrowserRouter,
  RouterProvider,
  // Outlet,
} from 'react-router-dom';
import {
  About,
  ContactMe,
  Home,
  Skills,
  Certificates,
  Work,
  ErrorPage,
} from "./pages";
import Layout from './Layout';
import "./App.scss";

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout/>,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <Home/>
      },
      {
        path: 'about',
        element: <About/>
      },
      {
        path: '/portfolio',
        element: <Work/>
      },
      {
        path: '/skills',
        element: <Skills/>
      },
      {
        path: '/certificates',
        element: <Certificates/>
      },
      {
        path: '/contact',
        element: <ContactMe/>
      },
    ],
  }
]);

const App = () => (
  <div className="app">
    <RouterProvider router={router} />
  </div>
);

export default App;
